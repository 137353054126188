<template>
  <ConfirmModal 
    title="Delete ingredient"
    description="If you will remove Potato starch this will also remove its associated data. This can’t be undone."
    confirm-btn-text="Delete" 
    reject-btn-text="Cancel" 
    img="stress-mark.svg" 
  />
</template>

<script setup lang="ts">

</script>