<template>
  <div class="confirm-modal">
    <img alt="icon" :src="getImagePath(img)" />

    <h2>{{ title }}</h2>

    <p>{{ description }}</p>

    <div class="confirm-modal__btn-block">
      <RedTextButton bo rounded="lg" width="45%" border :text="confirmBtnText" @click="emit('accept')" />

      <RedTonedButton rounded="lg" width="45%" :text="rejectBtnText" @click="emit('reject')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IConfirmModalsEmits } from '@/utils/types/interfaces/components';
import type { IConfirmModalProps } from '@/utils/types/interfaces/shared/props.interface';
import RedTextButton from '@/components/shared/Buttons/RedTextButton.vue';
import RedTonedButton from '@/components/shared/Buttons/RedTonedButton.vue';
import { getImagePath } from '@/utils/helpers';

defineProps<IConfirmModalProps>();
const emit = defineEmits<IConfirmModalsEmits>();
</script>

<style scoped lang="scss">
@import './ConfirmModal.scss';
</style>