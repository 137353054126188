import { defineStore } from 'pinia'
import type { State, Getters, Actions } from '@/utils/types/interfaces/store/business.interface'
import { BusinessService } from '@/services/business.service'

export const useBusinessStore = defineStore<'business', State, Getters, Actions>('business', {
  state: () => ({
    restaurants: [],
    activeRestaurantId: 2,
    currentRestaurant: null,
    tables: [],
    rooms: []
  }),
  getters: {
    getRestaurantById: (state) => (id) => state.restaurants?.find((r) => r.id === id),
    getActiveRestaurantRooms: (state) =>
      state.rooms.filter((room) => room.restaurant_id === state.activeRestaurantId),
    getActiveRestaurantTables: (state) =>
      state.tables.filter((table) => table.restaurant_id === state.activeRestaurantId),
    getActiveRestaurantWorkers: (state) =>
      state.currentRestaurant ? state.currentRestaurant.workers : []
  },
  actions: {
    async fetchRestaurants() {
      this.activeRestaurantId = 2

      const res = (await BusinessService.getAllRestaurants())?.data
      if (!res) return

      this.restaurants = res
    },
    async fetchRestaurantById(id: number) {
      const res = (await BusinessService.fetchCurrentRestaurant(id))?.data
      if (!res) return

      this.currentRestaurant = res
    },
    async fetchRooms() {
      const response = (await BusinessService.fetchAllRooms())?.data

      if (!response) return
      this.rooms = response
    },
    async fetchTables() {
      const response = (await BusinessService.fetchAllTables())?.data

      if (!response) return
      this.tables = response
    },
    async createTable({ name, enterpriseCode, numberOfSeats, roomId }) {
      await BusinessService.createTable(
        {
          name,
          enterprise_code: enterpriseCode,
          number_of_seats: numberOfSeats
        },
        roomId
      )

      await this.fetchTables()
    },
    async updateTable({ name, enterpriseCode, numberOfSeats, tableId }) {
      await BusinessService.updateTable(
        {
          name,
          enterprise_code: enterpriseCode,
          number_of_seats: numberOfSeats
        },
        tableId
      )

      await this.fetchTables()
    },
    async deleteTable(tableId: number) {
      await BusinessService.deleteTable(tableId)

      await this.fetchTables()
    },
    async getTableQr(tableId: number) {
      const response = (await BusinessService.getTableQr(tableId))?.data

      if (!response) return ''

      return response
    }
  }
})
