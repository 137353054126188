<template>
  <div class="add-menu">
    <div class="add-menu__add-image">
      <ImgInput
        variant="col"
        :is-modal="true"
        :imageUrl="image_url"
        :disabled="false"
        @uploaded="(data) => handleImageUpload(data)"
        @deleteImage="handleImageDelete"
        @close="removeModal"
      />
    </div>

    <div class="add-menu__controls">
      <v-form ref="addMenuForm" class="add-menu__controls--form">
        <Input
          title="Heading *"
          placeholder="Menu"
          :value="headerText"
          :rules="[requiredField, maxLength25]"
          @update:value="(val) => (headerText = val)"
        />

        <Textarea
          title="Description"
          placeholder="Your description"
          :rows="3"
          :max-length="56"
          :value="descriptionText"
          @update:value="(val) => (descriptionText = val)"
        />

        <p>
          You have a character limit, try to write a capacious but informative description for your
          title
        </p>
      </v-form>

      <div v-if="isEditing" class="add-menu__controls--category">
        <span> Cuisines </span>

        <IconTonedButton
          icon="add.svg"
          @click="addModal(MODALS_NAME.ADD_MENU_CUISINE, { mode: MODAL_MODE.NEW, data: null })"
        />
      </div>

      <ul v-if="isEditing" class="add-menu__controls--category-list">
        <li v-for="cuisine in cuisines" :key="cuisine.name">
          <GreyTonedButton
            :text="cuisine.name"
            @click="
              addModal(MODALS_NAME.ADD_MENU_CUISINE, {
                mode: MODAL_MODE.EDIT,
                data: toRaw(cuisine)
              })
            "
          />
        </li>
      </ul>

      <div class="add-menu__controls--buttons">
        <RedTextButton text="Cancel" @click="removeModal" />

        <RedTonedButton text="Continue" @click="submitMenu" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRaw } from 'vue'
import Input from '@/components/shared/Input/Input.vue'
import Textarea from '@/components/shared/Textarea/Textarea.vue'
import type { ILoadedFile } from '@/utils/types/interfaces/shared'
import RedTextButton from '@/components/shared/Buttons/RedTextButton.vue'
import RedTonedButton from '@/components/shared/Buttons/RedTonedButton.vue'
import IconTonedButton from '@/components/shared/Buttons/IconTonedButton.vue'
import { useModalStore } from '@/stores/modalStore'
import { MODALS_NAME, MODAL_MODE } from '@/utils/types/enums'
import { useMenuStore } from '@/stores/menuStore'
import { ROUTER_URL } from '@/utils/types/enums'
import router from '@/router'
import ImgInput from '@/components/shared/ImgInput/ImgInput.vue'
import GreyTonedButton from '@/components/shared/Buttons/GreyTonedButton.vue'
import { computed } from 'vue'
import { maxLength25, requiredField } from '@/utils/constants'
import type { VTextField } from 'vuetify/components'
import { storeToRefs } from 'pinia'

const modalStore = useModalStore()
const menuStore = useMenuStore()

const { addModal, removeModal, activeModalContext: context } = modalStore
const { addMenu, editMenu, uploadMenuMedia, deleteMenuMedia } = menuStore
const { cuisines } = storeToRefs(menuStore)

const isEditing = computed(() => {
  return context && context.mode === MODAL_MODE.EDIT
})

const addMenuForm = ref<VTextField | null>(null)

const menuItemId = ref(context?.data?.id || -1)
const headerText = ref(context?.data?.name || '')
const descriptionText = ref(context?.data?.subtitles || '')
const image_url = ref(context?.data?.image_url || '')

const imgFile = ref<ILoadedFile | undefined>()

function handleImageUpload(file: ILoadedFile) {
  imgFile.value = file
}

async function uploadImage(imgFile: ILoadedFile) {
  if (image_url.value) await handleImageDelete()

  const formData = new FormData()

  formData.append('image', imgFile.file)

  return (await uploadMenuMedia(menuItemId.value, formData))?.MediaURL || ''
}

async function handleMenuEdit() {
  let imageUrl = ''

  if (imgFile.value) imageUrl = await uploadImage(imgFile.value)

  await editMenu(
    {
      name: headerText.value,
      subtitles: descriptionText.value,
      image_url: imageUrl
    },
    menuItemId.value
  )
}

async function handleMenuCreate() {
  let imageUrl = ''

  const newMenuItem = await addMenu({
    name: headerText?.value,
    subtitles: descriptionText?.value,
    image_url: imageUrl
  })

  menuItemId.value = newMenuItem?.id || -1

  if (imgFile.value) imageUrl = await uploadImage(imgFile.value)

  await editMenu(
    {
      name: newMenuItem?.name || '',
      subtitles: newMenuItem?.subtitles || '',
      image_url: imageUrl
    },
    menuItemId.value
  )
}

async function handleModalClose() {
  removeModal()

  await router.push({
    path: ROUTER_URL.MENU_TEMPLATE.replace(':id', `${menuItemId.value}`)
  })
}

async function submitMenu() {
  if (!addMenuForm.value) return

  addMenuForm.value.validate()

  if (!addMenuForm.value.isValid) return

  if (isEditing.value) {
    await handleMenuEdit()
  } else {
    await handleMenuCreate()
  }

  await handleModalClose()
}

async function handleImageDelete() {
  await deleteMenuMedia(menuItemId.value)

  image_url.value = ''

  await editMenu(
    {
      name: headerText.value,
      subtitles: descriptionText.value,
      image_url: ''
    },
    menuItemId.value
  )
}
</script>

<style scoped lang="scss">
@import './AddMenu.scss';
</style>
