export enum ROUTER_URL {
  HOME = '/',
  MENU = '/managing/menu',
  MENU_TEMPLATE = '/managing/menu/:id',
  DISHES = '/managing/dishes',
  SINGLE_DISH_FORM = '/managing/dishes/:id',
  TABLES = '/managing/tables',
  SERVICING = '/servicing',
  ACCESS = '/access',
  CLIENTS = '/access/clients',
  REQUESTS = '/access/requests',
  SETTINGS = '/settings',
  LOGIN = '/login',
  SIGN_UP = '/sign-up',
  SIGN_UP_SENDED = '/sign-up/sended',
  RECOVERY = '/login/recovery',
  RECOVERY_CODE = '/login/code',
  RESET_PASSWORD = '/login/reset',
  RESET_PASSWORD_SUCCESS = '/login/reset-success',
  FETCH_USER_BY_ID = '/api/v1/business_acc/restaurant'
}
