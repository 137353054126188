<template>
  <div class="textarea-wrap">
    <span v-if="title" class="textarea-wrap__title">{{ title }}</span>

    <v-responsive class="mx-auto" max-width="100%">
      <v-textarea variant="solo" no-resize bg-color="input-gray" rounded="lg" :rows="rows || 2" :rules="rules || []"
        :placeholder="placeholder" :label="label" :autofocus="autofocus" @paste.prevent="pasteHandler" 
        v-model.trim="textareaValue" />
      </v-responsive>

    <span v-if="maxLength" class="textarea-wrap__counter">{{ valueLength }} / {{ maxLength }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { type TextareaProps, type ITextareaEmits } from '@/utils/types/interfaces/shared';

const props = defineProps<TextareaProps>();
const emit = defineEmits<ITextareaEmits>();

const textareaValue = ref<string>(props.value || '');

const valueLength = computed<number>(() => {
  return textareaValue.value ? textareaValue.value?.length : 0;
});

watch(
  textareaValue,
  (newVal, prevVal) => {
    if (newVal && prevVal && props.maxLength && newVal.length > props.maxLength) {
      textareaValue.value = prevVal;

      return;
    };

    emit('update:value', textareaValue.value);
  },
  {
    immediate: true,
  }
);

function pasteHandler(e: ClipboardEvent) {
  const paste = e.clipboardData?.getData("text");
  if (!paste || !props.maxLength) return;

  if (paste.length > props.maxLength) {
    textareaValue.value = paste.substring(0, props.maxLength);
  };
};
</script>

<style scoped lang="scss">
@import './Textarea.scss';
</style>