<template>
  <div class="input-wrap" :style="{ width: width || '100%' }">
    <span v-if="title" class="input-wrap__title">{{ title }}</span>

    <v-responsive class="mx-auto" max-width="100%">
      <v-text-field
        variant="solo"
        density="compact"
        bg-color="input-gray"
        rounded="lg"
        :type="type || 'text'"
        :rules="rules || []"
        :readonly="readonly"
        :placeholder="placeholder"
        :label="label"
        :autofocus="autofocus"
        :hide-details="hideDetails"
        :style="error ? { border: '1px solid red' } : ''"
        :error="error"
        :suffix="suffix || ''"
        v-model.trim="inputValue"
        @update:model-value="emit('update:value', inputValue)"
      >
        <template v-slot:append-inner>
          <img v-if="appendInner" :style="{ opacity: '0.6' }" :src="appendInner || ''" />
        </template>
      </v-text-field>
    </v-responsive>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { type IInputEmits, type InputProps } from '@/utils/types/interfaces/shared'
import { computed } from 'vue'

const props = defineProps<InputProps>()
const emit = defineEmits<IInputEmits>()

const inputValue = props.listenPropsVal
  ? computed<string>(() => props.value || '')
  : ref<string>(props.value || '')
</script>

<style scoped lang="scss">
@import './Input.scss';
</style>
