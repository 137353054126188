import { defineStore } from 'pinia'
import type {
  State,
  Getters,
  Actions,
  ISuperAdminUserView
} from '@/utils/types/interfaces/store/access.interface'
import { SuperAdminService } from '@/services/super-admin.service'
import type { IRequest } from '@/utils/types/interfaces/components/requests.interface'
import { useBusinessStore } from '@/stores/businessStore'

export const useAccessStore = defineStore<'access', State, Getters, Actions>('access', {
  state: () => ({
    allUsers: [],
    userRequests: []
  }),
  getters: {
    getInactiveRequests: (state) => state.userRequests.filter((request) => !request.is_active)
  },
  actions: {
    async fetchAllUsers() {
      const users = (await SuperAdminService.getAllUsers())?.data

      if (!users) return

      this.allUsers = users

      this.userRequests = users.map(
        (user: ISuperAdminUserView): IRequest => ({
          ...user,
          company: 'company',
          owner: 'owner',
          number: 'number'
        })
      )
    },
    async sendRequestResponse({ userId, response }) {
      const res = (await SuperAdminService.sendRequestResponse({ userId, response }))?.data

      if (!res) return

      this.userRequests = [...this.userRequests].map((request) =>
        request?.id === userId ? { ...request, is_active: response } : request
      )
    },
    async createWorker(input) {
      await SuperAdminService.createWorker(input)

      const { fetchRestaurantById } = useBusinessStore()

      await fetchRestaurantById(2)
    },
    async updateWorker(input) {
      await SuperAdminService.updateWorker(input)

      const { fetchRestaurantById } = useBusinessStore()

      await fetchRestaurantById(2)
    },
    async deleteWorker(id) {
      await SuperAdminService.deleteWorker(id)

      const { fetchRestaurantById } = useBusinessStore()

      await fetchRestaurantById(2)
    }
  }
})
