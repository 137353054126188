<template>
  <div class="add-client">
    <div class="modal-header">
      <span class="modal-title"> {{ isEditing ? 'Edit worker' : 'Add worker' }}</span>

      <button @click="removeModal">
        <img alt="close" :src="getImagePath('close.svg')" />
      </button>
    </div>

    <div class="modal-form">
      <div class="client-avatar-placeholder">
        <img :src="getImagePath('avatar.svg')" alt="avatar-placeholder" />
      </div>

      <div class="form-inputs-container">
        <Input title="Full name" placeholder="None" v-model:value="fullName" />

        <Input title="Email" placeholder="example@mail.com" v-model:value="email" />

        <Input title="Phone number" placeholder="+(000) 000-00-00" v-model:value="phoneNumber" />

        <Select title="Role" placeholder="None" :items="['default', 'vip']" />

        <Select
          title="Work place"
          placeholder="None"
          :items="['default', 'place']"
          :style="{ flexBasis: '100%' }"
        />
      </div>
    </div>

    <div class="modal-footer">
      <RedTextButton v-if="isEditing" text="Remove" @click="handleWorkerTable" />
      <RedTextButton v-else text="Cancel" @click="removeModal" />

      <RedTonedButton text="Accept" @click="handleWorkerCreate" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { getImagePath } from '@/utils/helpers'
import RedTonedButton from '@/components/shared/Buttons/RedTonedButton.vue'
import RedTextButton from '@/components/shared/Buttons/RedTextButton.vue'
import { useModalStore } from '@/stores/modalStore'
import Input from '@/components/shared/Input/Input.vue'
import Select from '@/components/shared/Select/Select.vue'
import { ref } from 'vue'
import { useAccessStore } from '@/stores/accessStore'
import { computed } from 'vue'
import { MODAL_MODE } from '@/utils/types/enums'

const { removeModal, activeModalContext: context } = useModalStore()

const { createWorker, updateWorker, deleteWorker } = useAccessStore()

const fullName = ref(context?.data?.full_name || '')
const email = ref(context?.data?.email || '')
const phoneNumber = ref(context?.data?.phone || '')

const isEditing = computed(() => {
  return context && context.mode === MODAL_MODE.EDIT
})

const handleWorkerTable = async () => {
  await deleteWorker(context?.data?.id)

  removeModal()
}

const handleWorkerCreate = async () => {
  if (fullName.value && email.value && phoneNumber.value) {
    if (!isEditing.value) {
      await createWorker({
        email: email.value,
        full_name: fullName.value,
        phone: phoneNumber.value,
        password: 'temporaryPassword'
      })
    } else {
      await updateWorker({
        id: context?.data?.id,
        email: email.value,
        full_name: fullName.value,
        phone: phoneNumber.value,
        password: 'temporaryPassword'
      })
    }

    removeModal()
  }
}
</script>

<style scoped lang="scss">
@import 'AddClient';
</style>
