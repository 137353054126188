<template>
  <v-btn 
    ripple 
    size="large" 
    rounded="lg" 
    class="text-none bg-primary-red px-0" 
    min-width="30" 
    height="30" 
    variant="tonal"
    :disabled="disabled" 
    @click="emit('click')"
  >
    <template v-slot:default>
      <img alt="img" :src="getImagePath(icon)" />
    </template>
  </v-btn>
</template>

<script setup lang="ts">
import { getImagePath } from '@/utils/helpers';
import { type IButtonEmits, type IconTonedButtonProps } from '@/utils/types/interfaces/shared';


defineProps<IconTonedButtonProps>();
const emit = defineEmits<IButtonEmits>();
</script>
