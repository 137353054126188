<template>
  <div class="img-input" :style="{ backgroundImage: `url(${uploadedImgSrc || imageUrl})` }">
    <template v-if="!uploadedImgSrc && !imageUrl">
      <label>
        <input
          type="file"
          :accept="extensionValue"
          :disabled="disabled"
          :multiple="multiple"
          @change.prevent="uploadFileHandler"
        />
      </label>

      <div :class="`img-input__caption ${variant}`">
        <img alt="load" :src="getImagePath('download.svg')" />

        <div class="img-input__caption--text">
          <h2>Add a banner image</h2>

          <p>Optimal dimensions 1125x738px</p>
        </div>
      </div>

      <button v-if="isModal" class="img-input__close-btn" @click.stop="emit('close')">
        <img alt="close" :src="getImagePath('modal-close.svg')" />
      </button>
    </template>

    <div v-else>
      <input
        v-show="false"
        ref="filesInput"
        type="file"
        :accept="extensionValue"
        :disabled="disabled"
        :multiple="multiple"
        @change.prevent="uploadFileHandler"
      />
      <button v-if="isModal" class="img-input__close-btn" @click.stop="emit('close')">
        <img alt="close" :src="getImagePath('modal-close.svg')" />
      </button>

      <!--        <button v-if="imageUrl" class="img-input__close-btn" @click.stop="emit('deleteImage')">-->
      <!--          <span>Delete</span>-->
      <!--        </button>-->

      <div class="img-input__delete-btn">
        <DarkTonedButton
          text=""
          center-icon="delete.svg"
          min-width="40px"
          @click="handleImageDelete"
        />
      </div>

      <div class="img-input__edit-btn">
        <DarkTonedButton text="Edit banner" right-icon="edit.svg" @click="handleImageEdit" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { Ref } from 'vue'
import { ALLOWED_IMAGE_EXTENSIONS } from '@/utils/constants/system.const'
import type { IImgInputEmits } from '@/utils/types/interfaces/shared'
import DarkTonedButton from '@/components/shared/Buttons/DarkTonedButton.vue'
import { getImagePath } from '@/utils/helpers'

const props = defineProps({
  disabled: Boolean,
  multiple: Boolean,
  imageUrl: String,
  variant: String,
  isModal: Boolean
})
const emit = defineEmits<IImgInputEmits>()

const uploadedImgSrc = ref<string | ArrayBuffer>('')
const filesInput: Ref<HTMLInputElement | null> = ref(null)

const extensionsList = ALLOWED_IMAGE_EXTENSIONS
const extensionValue = extensionsList.map((v) => `.${v}`).join(',')

function checkExtensions(file: File | undefined | null) {
  if (!file) return false

  const [, extension] = file.type.split('/')

  return extensionsList.includes(extension)
}

function uploadFileHandler(e: Event) {
  if (props.disabled && e.target) return

  const target = e.target as HTMLInputElement
  const file: File | null | undefined = target.files?.[0]

  if (!file || !checkExtensions(file)) return

  const fileReader = new FileReader()

  fileReader.readAsDataURL(file)
  fileReader.addEventListener(
    'loadend',
    (event) => {
      emit('uploaded', {
        file,
        src: event.target?.result || '',
        name: file.name
      })

      uploadedImgSrc.value = event.target?.result || ''
    },
    { once: true }
  )

  target.value = ''
}

const handleImageDelete = () => {
  uploadedImgSrc.value = ''

  if (props.imageUrl) emit('deleteImage')
}

const handleImageEdit = () => {
  if (filesInput.value) filesInput.value.click()
}
</script>

<style scoped>
@import './ImgInput.scss';
</style>
