import type { STORAGE_KEYS } from "@/utils/types/enums";

class StorageService {
  static set = <T>(key: STORAGE_KEYS, value: T): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  static get = <T>(key: STORAGE_KEYS): T | null => {
    const item = localStorage.getItem(key);

    if (item !== null && !!item.length) return JSON.parse(item);

    return null;
  };

  static remove = (key: STORAGE_KEYS): void => localStorage.removeItem(key);

  static clear = (): void => localStorage.clear();
}

export { StorageService };