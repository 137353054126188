export enum MODALS_NAME {
  ADD_MENU = 'add_menu',
  ADD_TABLE = 'add_table',
  ADD_CATEGORY = 'add_category',
  ADD_CUISINE = 'add_cuisine',
  ADD_INGREDIENT = 'add_ingredient',
  ADD_TAG = 'add_tag',
  ADD_MENU_CUISINE = 'add_menu_cuisine',
  ADD_CLIENT = 'add_client',
  CONFIRM_ITEM_DELETE = 'confirm_item_delete',
  CONFIRM_DISH_DELETE = 'confirm_dish_delete',
  CONFIRM_INGREDIENT_DELETE = 'confirm_ingredient_delete',
  CONFIRM_UNSAVED = 'confirm_unsaved',
  CONFIRM_LANG_CHANGE = 'confirm_lang_change'
}

export enum MODAL_MODE {
  EDIT = 'edit',
  NEW = 'new'
}
