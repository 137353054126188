<template>
  <div class="modal_wrap" @click.self="removeModal()">
    <v-slide-y-reverse-transition>
      <div v-show="show">
        <slot  />
      </div>
    </v-slide-y-reverse-transition> 
  </div>
</template>

<script setup lang="ts">
import { useModalStore } from '@/stores/modalStore';
import type { ModalLayoutProps } from '@/utils/types/interfaces/components/layout.interface';
import { onMounted } from 'vue';
import { ref } from 'vue';

const { removeModal } = useModalStore();
defineProps<ModalLayoutProps>();

const show = ref(false);

onMounted(() => show.value = true);
</script>

<style lang="scss" scoped>
@import './ModalLayout.scss';
</style>