import { StorageService } from "@/services/storage.service";
import { STORAGE_KEYS } from "@/utils/types/enums";

export const isTokenExpired = (): boolean => {
  const lifeTime = StorageService.get<number>(STORAGE_KEYS.ACCESS_TOKEN_LIFE_TIME);

  if (!lifeTime) return true;

  const now = Math.trunc(Date.now() / 1000);//calculation needs to normalize JS timestamp according to BE timestamp format

  return (lifeTime - now) < 60;
};