<template>
  <div 
    class="loader_wrap" 
  >
    <slot></slot>
  </div>
</template>


<style lang="scss" scoped>
@import './LoaderLayout.scss';
</style>