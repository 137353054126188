<template>
  <div class="login">
    <div class="login-wrap">
      <div class="login-wrap__img" />

      <div class="login-wrap__form">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
@import './LoginFormLayout.scss';
</style>