export enum DRAWER_NAMES {
  FILTER = 'filter',
  CURRENCY = 'currency',
  MENU = 'menu',
  CUISINE = 'cuisine',
  CATEGORY = 'category',
  INGREDIENTS = 'ingredient',
  TAGS = 'tags',
  DESIGNATIONS = 'designations',
  TRANSLATION = 'translation'
}
