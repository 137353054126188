import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { ROUTER_NAMES, ROUTER_URL } from '@/utils/types/enums'
import { authGuard } from './guards/auth.guard'

const routes: RouteRecordRaw[] = [
  {
    path: ROUTER_URL.HOME,
    name: ROUTER_NAMES.HOME,
    component: () => import('@/views/Dashboard.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.MENU,
    name: ROUTER_NAMES.MENU,
    component: () => import('@/views/MenusList/MenusList.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.MENU_TEMPLATE,
    name: ROUTER_NAMES.MENU_TEMPLATE,
    component: () => import('@/views/MenuItem/MenuItem.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.DISHES,
    name: ROUTER_NAMES.DISHES,
    component: () => import('@/views/Dishes/Dishes.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.SINGLE_DISH_FORM,
    name: ROUTER_NAMES.SINGLE_DISH_FORM,
    component: () => import('@/views/SingleDishForm/SingleDishForm.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.TABLES,
    name: ROUTER_NAMES.TABLES,
    component: () => import('@/views/Tables/Tables.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.SERVICING,
    name: ROUTER_NAMES.SERVICING,
    component: () => import('@/views/Servicing.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.ACCESS,
    name: ROUTER_NAMES.ACCESS,
    component: () => import('@/views/Access/Access.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.CLIENTS,
    name: ROUTER_NAMES.CLIENTS,
    component: () => import('@/views/Access/Clients/Clients.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.REQUESTS,
    name: ROUTER_NAMES.REQUESTS,
    component: () => import('@/views/Access/Requests/Requests.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.SETTINGS,
    name: ROUTER_NAMES.SETTINGS,
    component: () => import('@/views/Settings.vue'),
    meta: { withAuthCheck: true }
  },
  {
    path: ROUTER_URL.LOGIN,
    name: ROUTER_NAMES.LOGIN,
    components: {
      auth: () => import('@/components/AuthForms/Login/LoginForm.vue')
    },
    meta: { withAuthCheck: false, auth: true }
  },
  {
    path: ROUTER_URL.SIGN_UP,
    name: ROUTER_NAMES.SIGN_UP,
    components: {
      auth: () => import('@/components/AuthForms/SignUp/SignUp.vue')
    },
    meta: { withAuthCheck: false, auth: true }
  },
  {
    path: ROUTER_URL.SIGN_UP_SENDED,
    name: ROUTER_NAMES.SIGN_UP_SENDED,
    components: {
      auth: () => import('@/components/AuthForms/RequestSent/RequestSent.vue')
    },
    meta: { withAuthCheck: false, auth: true }
  },
  {
    path: ROUTER_URL.RECOVERY,
    name: ROUTER_NAMES.RECOVERY,
    components: {
      auth: () => import('@/components/AuthForms/ForgotPassword/ForgotPassword.vue')
    },
    meta: { withAuthCheck: false, auth: true }
  },
  {
    path: ROUTER_URL.RECOVERY_CODE,
    name: ROUTER_NAMES.RECOVERY_CODE,
    components: {
      auth: () => import('@/components/AuthForms/RecoveryCode/RecoveryCode.vue')
    },
    meta: { withAuthCheck: false, auth: true }
  },
  {
    path: ROUTER_URL.RESET_PASSWORD,
    name: ROUTER_NAMES.RESET_PASSWORD,
    components: {
      auth: () => import('@/components/AuthForms/ResetPassword/ResetPassword.vue')
    },
    meta: { withAuthCheck: false, auth: true }
  },
  {
    path: ROUTER_URL.RESET_PASSWORD_SUCCESS,
    name: ROUTER_NAMES.RESET_PASSWORD_SUCCESS,
    components: {
      auth: () => import('@/components/AuthForms/PasswordCreated/PasswordCreated.vue')
    },
    meta: { withAuthCheck: false, auth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(authGuard)

export default router
