import type {
  IAdditionalComponent,
  IDishIngredient,
  IDishRequest
} from '@/utils/types/interfaces/components/dish.interface'
import { defineStore } from 'pinia'
import type { Actions, Getters, State } from '@/utils/types/interfaces/store/dish.interface'
import { MenuItemService } from '@/services/menu-item.service'
import { createObjectWithout } from '@/utils/helpers'

export const useDishStore = defineStore<'dish', State, Getters, Actions>('dish', {
  state: () => ({
    dishFormData: {
      id: 0,
      menu_id: 0,
      category_id: 0,
      menu_name: '',
      category_name: '',
      name: '',
      currency: '',
      cuisine_type: '',
      cuisine_type_id: 0,
      portion_size: 0,
      cooking_time: '',
      image_url: '',
      photo_name: `${Date.now()}-mocked photo name`,
      description: 'mocked description',
      price: 0,
      ingredients: [],
      tags: [],
      additionalComponents: []
    },
    menuItemsList: []
  }),
  actions: {
    async createMenuItem(isActive) {
      const body: Partial<IDishRequest> = createObjectWithout(this.dishFormData, [
        'category_id',
        'menu_id'
      ])

      body.is_available = !!isActive
      body.cooking_time = body.cooking_time + ' min'

      const res = (
        await MenuItemService.createMenuItem(
          body as IDishRequest,
          this.dishFormData.menu_id,
          this.dishFormData.category_id
        )
      )?.data
      if (!res) return undefined

      await this.fetchAllMenuItems()

      return res
    },

    async deleteDish(id) {
      const res = (await MenuItemService.deleteDish(id))?.data

      if (!res) return

      this.fetchAllMenuItems()
    },

    async editMenuItem(body, menuItemId) {
      const res = (await MenuItemService.editMenuItem(body, menuItemId))?.data

      if (!res) return
    },

    async fetchAllMenuItems() {
      const menuItems = (await MenuItemService.getAllMenuItems())?.data
      if (menuItems) {
        this.menuItemsList = await Promise.all(
          menuItems.map(async (menuItem) => {
            const sectionId = menuItem.section_id
            const section = await this.getSectionById(sectionId)

            return {
              ...menuItem,
              section: section ? section?.name : null
            }
          })
        )
      }
    },

    async getDishMedia(menuId) {
      const res = await MenuItemService.getDishMedia(menuId)

      const isRequestSuccessful = res?.status === 200

      if (!isRequestSuccessful && !res?.data) return

      // return res?.data
    },

    async getSectionById(sectionId: number) {
      const section = (await MenuItemService.getSectionById(sectionId))?.data
      if (!section) return null

      return section
    },

    addIngredient(ingredient: IDishIngredient) {
      this.dishFormData.ingredients = [...this.dishFormData.ingredients, ingredient]
    },

    addTag(tag: string) {
      this.dishFormData.tags = [...this.dishFormData.tags, tag]
    },

    addAdditionalComponent(additionalComponent: IAdditionalComponent) {
      this.dishFormData.additionalComponents = [
        ...this.dishFormData.additionalComponents,
        additionalComponent
      ]
    },

    async getCuisineById(cuisineId: number) {
      const cuisine = (await MenuItemService.getCuisineById(cuisineId))?.data
      if (!cuisine) return null

      return cuisine
    },

    async uploadDishMedia(dishId, imageFile) {
      const res = (await MenuItemService.uploadDishMedia(dishId, imageFile))?.data

      if (!res) return null

      return res
    },

    async deleteDishMedia(dishId) {
      const res = (await MenuItemService.deleteDishMedia(dishId))?.data

      if (!res) return
    },

    setDishFormData(data) {
      this.dishFormData = {
        ...this.dishFormData,
        ...data
      }
    },

    resetStore() {
      this.$reset()
    }
  }
})
