import type { IRegisterRequest } from '@/utils/types/interfaces/services/auth'
import { ENDPOINTS } from '@/utils/types/enums'
import { API } from './api'
import { errorHandledTryCatch } from '@/utils/helpers'
import type { ICreateTable, IGetRestaurantsResponse } from '@/utils/types/interfaces/services'
import { useBusinessStore } from '@/stores/businessStore'

class BusinessService {
  static createRestaurant = (body: IRegisterRequest) =>
    errorHandledTryCatch<IRegisterRequest>(() => API.post(ENDPOINTS.CREATE_RESTAURANT, body))

  static getAllRestaurants = () =>
    errorHandledTryCatch<Array<IGetRestaurantsResponse>>(() =>
      API.get(ENDPOINTS.GET_ALL_RESTAURANTS)
    )

  static fetchCurrentRestaurant = (id: number) =>
    errorHandledTryCatch<IGetRestaurantsResponse>(() =>
      API.get(ENDPOINTS.GET_RESTAURANT_BY_ID + `/${id}`)
    )

  static fetchAllRooms = () => API.get(ENDPOINTS.GET_ALL_ROOMS)

  static fetchAllTables = () => API.get(ENDPOINTS.GET_ALL_TABLES)

  static createTable = async (body: ICreateTable, room_id: number) => {
    const { activeRestaurantId } = useBusinessStore()
    if (activeRestaurantId === null) return

    return errorHandledTryCatch<ICreateTable>(() =>
      API.post(ENDPOINTS.CREATE_TABLE, body, {
        params: { restaurant_id: activeRestaurantId, room_id }
      })
    )
  }

  static updateTable = async (body: ICreateTable, tableId: number) => {
    return errorHandledTryCatch<ICreateTable>(() =>
      API.put(ENDPOINTS.UPDATE_TABLE + `/${tableId}/`, body)
    )
  }

  static deleteTable = async (tableId: number) => {
    return errorHandledTryCatch<ICreateTable>(() =>
      API.delete(ENDPOINTS.DELETE_TABLE + `/${tableId}/`)
    )
  }

  static getTableQr = async (tableId: number) =>
    API.post(ENDPOINTS.GET_TABLE_QR, null, {
      params: { table_id: tableId }
    })
}

export { BusinessService }
