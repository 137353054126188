import type { ICategory, ICuisine } from '@/utils/types/interfaces/store/menu.interface'
import type {
  ICategoryResponse,
  ICuisinesResponse,
  IMenuItem
} from '@/utils/types/interfaces/services/menu'
import type { ICreateMenu, IImageUploadResponse } from '@/utils/types/interfaces/services'
import { ENDPOINTS } from '@/utils/types/enums'
import { API, FormDataApi } from './api'
import { errorHandledTryCatch, insertQueryParam } from '@/utils/helpers'
import { useBusinessStore } from '@/stores/businessStore'

class MenuService {
  static createMenu = async (body: ICreateMenu) => {
    const { activeRestaurantId } = useBusinessStore()
    if (activeRestaurantId === null) return

    return errorHandledTryCatch<IMenuItem>(() =>
      API.post(ENDPOINTS.CREATE_MENU, body, { params: { restaurant_id: activeRestaurantId } })
    )
  }

  static fetchAllMenus = async () =>
    errorHandledTryCatch<IMenuItem[]>(() => API.get(ENDPOINTS.GET_ALL_MENUS))

  static fetchMenuById = async (id: number) =>
    errorHandledTryCatch<IMenuItem>(() => API.get(ENDPOINTS.GET_MENU, { params: { menu_id: id } }))

  static updateMenu = async (body: ICreateMenu, id: number) =>
    errorHandledTryCatch<ICreateMenu>(() =>
      API.put(insertQueryParam(id, ENDPOINTS.UPDATE_MENU), body)
    )

  static deleteMenu = async (id: number) =>
    errorHandledTryCatch<ICreateMenu>(() => API.delete(insertQueryParam(id, ENDPOINTS.DELETE_MENU)))

  static getMenuMedia = async (menuId: number) =>
    errorHandledTryCatch<string>(() => API.get(insertQueryParam(menuId, ENDPOINTS.MENU_MEDIA)))

  static uploadMenuMedia = async (menuId: number, imageFile: FormData) =>
    errorHandledTryCatch<IImageUploadResponse>(() =>
      FormDataApi.post(insertQueryParam(menuId, ENDPOINTS.MENU_MEDIA), imageFile)
    )

  static deleteMenuMedia = async (menuId: number) =>
    errorHandledTryCatch<string>(() => API.delete(insertQueryParam(menuId, ENDPOINTS.MENU_MEDIA)))

  static addCategory = async (body: ICategory, cuisineId: number) => {
    return errorHandledTryCatch<ICategory>(() =>
      API.post(ENDPOINTS.CREATE_SECTION, body, { params: { cuisine_id: cuisineId } })
    )
  }

  static updateCategory = async (body: ICategory, id: number) =>
    errorHandledTryCatch<ICategory>(() =>
      API.put(insertQueryParam(id, ENDPOINTS.UPDATE_SECTION), body)
    )

  static fetchCategory = async (id: number) =>
    errorHandledTryCatch<ICategoryResponse>(() =>
      API.get(ENDPOINTS.FETCH_SECTION, { params: { section_id: id } })
    )

  static fetchAllCategories = async (cuisineId: number) =>
    errorHandledTryCatch<ICategoryResponse[]>(() =>
      API.get(ENDPOINTS.FETCH_ALL_MENU_SECTIONS, { params: { cuisine_id: cuisineId } })
    )

  static deleteCategory = async (id: number) =>
    errorHandledTryCatch<Pick<ICategoryResponse, 'name'>>(() =>
      API.delete(insertQueryParam(id, ENDPOINTS.DELETE_SECTION))
    )

  static addCuisine = async (body: ICuisine, menuId: number) => {
    return errorHandledTryCatch<ICuisine>(() =>
      API.post(ENDPOINTS.CREATE_CUISINE, body, { params: { menu_id: menuId } })
    )
  }

  static updateCuisine = async (body: ICuisine, id: number) =>
    errorHandledTryCatch<ICuisine>(() =>
      API.put(insertQueryParam(id, ENDPOINTS.UPDATE_CUISINE), body)
    )

  static fetchCuisine = async (id: number) =>
    errorHandledTryCatch<ICuisinesResponse>(() =>
      API.get(ENDPOINTS.FETCH_CUISINE, { params: { section_id: id } })
    )

  static fetchAllCuisines = async (menuId: number) =>
    errorHandledTryCatch<ICuisinesResponse[]>(() =>
      API.get(ENDPOINTS.FETCH_ALL_MENU_CUISINES, { params: { menu_id: menuId } })
    )

  static deleteCuisine = async (id: number) =>
    errorHandledTryCatch<Pick<ICuisinesResponse, 'name'>>(() =>
      API.delete(insertQueryParam(id, ENDPOINTS.DELETE_CUISINE))
    )
}

export { MenuService }
