<template>
  <div v-if="activeDrawer?.withAddButton" class="add-btn" @click="addHandler">
    <img alt="plus" :src="getImagePath('add-dark.svg')" />

    <span> {{ $t('buttons.add') }} {{ activeDrawer.name }} </span>
  </div>

  <ul class="menu-list">
    <li
      v-for="item in renderData"
      :key="item[0]"
      :class="`menu-list__item ${context.returnedData[1] === item[1] ? 'selected' : ''}`"
      @click="() => setReturnedData(item)"
    >
      <span>
        {{ item[0] }}
      </span>

      <img alt="check" :src="getImagePath('check-sign-white.svg')" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useDrawerStore } from '@/stores/drawerStore'
import { storeToRefs } from 'pinia'
import { getImagePath } from '@/utils/helpers'
import { useDishStore } from '@/stores/dishStore'
import { onMounted } from 'vue'
import { computed } from 'vue'
import { DRAWER_NAMES } from '@/utils/types/enums/drawer-names.enum'
import { useModalStore } from '@/stores/modalStore'
import { LANGUAGES_NAMES, MODALS_NAME } from '@/utils/types/enums'
import { useMenuStore } from '@/stores/menuStore'
import type { drawerReturnedDataType } from '@/utils/types/interfaces/store/drawer.interface'
import i18n from '@/i18n'

const drawerStore = useDrawerStore()
const dishStore = useDishStore()
const modalStore = useModalStore()
const menuStore = useMenuStore()

const { setReturnedData } = drawerStore
const { addModal } = modalStore

const { context, activeDrawer } = storeToRefs(drawerStore)
const { dishFormData } = storeToRefs(dishStore)
const { menuList, categories, cuisines, currency } = storeToRefs(menuStore)

const addHandler = computed(() => {
  if (activeDrawer.value?.name === DRAWER_NAMES.CATEGORY)
    return () => addModal(MODALS_NAME.ADD_CATEGORY)

  if (activeDrawer.value?.name === DRAWER_NAMES.CUISINE)
    return () => addModal(MODALS_NAME.ADD_CUISINE)

  if (activeDrawer.value?.name === DRAWER_NAMES.INGREDIENTS)
    return () => addModal(MODALS_NAME.ADD_INGREDIENT)

  if (activeDrawer.value?.name === DRAWER_NAMES.TAGS) return () => addModal(MODALS_NAME.ADD_TAG)
  return () => {}
})

const renderData = computed<drawerReturnedDataType[]>(() => {
  if (activeDrawer.value?.name === DRAWER_NAMES.MENU)
    return menuList.value.map((m) => [m.name, m.id])
  if (activeDrawer.value?.name === DRAWER_NAMES.CATEGORY)
    return categories.value.map((c) => [c.name, c.id])
  if (activeDrawer.value?.name === DRAWER_NAMES.CUISINE) {
    return cuisines.value.map((c) => [c.name, c.id])
  }
  if (activeDrawer.value?.name === DRAWER_NAMES.INGREDIENTS) {
    return dishFormData.value.ingredients.map((c, i) => [c.name, i])
  }
  if (activeDrawer.value?.name === DRAWER_NAMES.TAGS) {
    return dishFormData.value.tags.map((c, i) => [c, i])
  }

  if (activeDrawer.value?.name === DRAWER_NAMES.CURRENCY)
    return currency.value.map((c, i) => [c, i])
  if (activeDrawer.value?.name === DRAWER_NAMES.TRANSLATION)
    return i18n.global.availableLocales.map((language, i) => [
      LANGUAGES_NAMES[language as keyof typeof LANGUAGES_NAMES],
      i
    ])

  return []
})

onMounted(() => {
  if (dishFormData.value.menu_name && activeDrawer.value?.name === DRAWER_NAMES.MENU) {
    setReturnedData([dishFormData.value.menu_name, dishFormData.value.menu_id])
  }

  if (dishFormData.value.category_id && activeDrawer.value?.name === DRAWER_NAMES.CATEGORY) {
    setReturnedData([dishFormData.value.category_name, dishFormData.value.category_id])
  }

  if (dishFormData.value.cuisine_type_id && activeDrawer.value?.name === DRAWER_NAMES.CUISINE) {
    setReturnedData([dishFormData.value.cuisine_type, dishFormData.value.cuisine_type_id])
  }

  if (dishFormData.value.currency && activeDrawer.value?.name === DRAWER_NAMES.CURRENCY) {
    setReturnedData(renderData.value.find((c) => c[0] === dishFormData.value.currency) || [])
  }
})
</script>

<style scoped lang="scss">
@import './SimpleList.scss';
</style>
