<template>
  <div class="add-table">
    <div class="modal-header">
      <span class="modal-title">{{ isEditing ? 'Edit table' : 'Add table' }}</span>

      <button @click="removeModal">
        <img alt="close" :src="getImagePath('close.svg')" />
      </button>
    </div>

    <div class="modal-form">
      <div v-if="qrCode" class="qr-container">
        <SvgByCode :code="qrCode" />
      </div>
      <div v-else class="qr-placeholder">
        <img :src="getImagePath('export.svg')" alt="export" />
        <span class="upload-qr"> Upload QR </span>
      </div>

      <div class="form-inputs-container">
        <Input
          title="Enterprise code"
          placeholder="Your code"
          v-model:value="enterpriseCode"
          :style="{ flexBasis: '100%' }"
        />

        <Input title="Amount" placeholder="Table capacity" v-model:value="tableAmount" />

        <Input title="Table name" placeholder="Table name" v-model:value="tableName" />

        <Select
          title="Place"
          placeholder="Table arrangement"
          :items="roomItems"
          :default-value="defaultRoomValue"
          @select:value="(value) => handleRoomSelect(value)"
        />

        <Select title="Category" placeholder="None" :items="['default', 'vip']" />
      </div>
    </div>

    <div class="modal-footer">
      <RedTextButton v-if="isEditing" text="Remove" @click="handleDeleteTable" />
      <RedTextButton v-else text="Cancel" @click="removeModal" />

      <RedTonedButton text="Accept" @click="handleTableCreate" />
    </div>
  </div>
</template>

<script setup lang="ts">
import RedTonedButton from '@/components/shared/Buttons/RedTonedButton.vue'
import RedTextButton from '@/components/shared/Buttons/RedTextButton.vue'
import { useModalStore } from '@/stores/modalStore'
import { getImagePath } from '@/utils/helpers'
import Input from '@/components/shared/Input/Input.vue'
import { computed, onMounted, ref } from 'vue'
import SvgByCode from '@/components/shared/SvgByCode/SvgByCode.vue'
import Select from '@/components/shared/Select/Select.vue'
import { useBusinessStore } from '@/stores/businessStore'
import { storeToRefs } from 'pinia'
import { MODAL_MODE } from '@/utils/types/enums'
import type { IRoom } from '@/utils/types/interfaces/components'

const { removeModal, activeModalContext: context } = useModalStore()

const restaurantStore = useBusinessStore()

const { createTable, updateTable, deleteTable, getTableQr } = restaurantStore

const { getActiveRestaurantRooms } = storeToRefs(restaurantStore)

const roomItems = computed(() => getActiveRestaurantRooms.value.map((room: IRoom) => room.name))
const defaultRoomValue = computed(
  () =>
    getActiveRestaurantRooms.value.find((room: IRoom) => room.id === context?.data?.room_id)?.name
)

const isEditing = computed(() => {
  return context && context.mode === MODAL_MODE.EDIT
})

const qrCode = ref('')
const enterpriseCode = ref(context?.data?.enterprise_code || '')
const tableAmount = ref(context?.data?.number_of_seats || 1)
const tableName = ref(context?.data?.name || '')
const tableRoom = ref(context?.data?.room_id || null)

onMounted(async () => {
  if (isEditing.value) qrCode.value = await getTableQr(context?.data?.id)
})

const handleRoomSelect = (roomName: string) => {
  tableRoom.value = getActiveRestaurantRooms.value.find((room) => room.name === roomName)?.id || 1
}

const handleDeleteTable = async () => {
  await deleteTable(context?.data?.id)

  removeModal()
}

const handleTableCreate = async () => {
  if (tableRoom.value && tableName.value && tableAmount.value) {
    if (!isEditing.value) {
      await createTable({
        name: tableName.value,
        enterpriseCode: enterpriseCode.value,
        roomId: tableRoom.value,
        numberOfSeats: Number(tableAmount.value)
      })
    } else {
      await updateTable({
        name: tableName.value,
        enterpriseCode: enterpriseCode.value,
        tableId: context?.data?.id,
        numberOfSeats: Number(tableAmount.value)
      })
    }

    removeModal()
  }
}
</script>

<style scoped lang="scss">
@import 'AddTable';
</style>
