<template>
  <v-btn
    ripple
    size="large"
    variant="tonal"
    class="text-none bg-primary-red text-primary-white"
    :text="text"
    :width="width || 'unset'"
    :height="height || '36px'"
    :rounded="rounded || 'xl'"
    :disabled="disabled"
    :density="density || 'default'"
    @click="emit('click')"
  >
    <template v-if="rightIcon" v-slot:prepend>
      <img alt="icon" :src="getImagePath(rightIcon)" />
    </template>

    <template v-if="leftIcon" v-slot:append>
      <img alt="icon" :src="getImagePath(leftIcon)" />
    </template>
  </v-btn>
</template>

<script setup lang="ts">
import { getImagePath } from '@/utils/helpers'
import { type ButtonProps, type IButtonEmits } from '@/utils/types/interfaces/shared'

defineProps<ButtonProps>()
const emit = defineEmits<IButtonEmits>()
</script>
