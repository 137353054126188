export enum ENDPOINTS {
  // Auth
  LOGIN = '/api/v1/auth/token',
  LOGOUT = '/api/v1/auth/logout',
  REGISTER = '/api/v1/auth/register',
  REFRESH_TOKEN = '/api/v1/auth/refresh-token',
  CURRENT_USER = '/api/v1/auth/current-user',

  // Menu
  CREATE_MENU = '/api/v1/restaurant_menu/menu',
  GET_ALL_MENUS = '/api/v1/restaurant_menu/menu',
  GET_MENU = '/api/v1/restaurant_menu/menu',
  UPDATE_MENU = '/api/v1/restaurant_menu/menu',
  DELETE_MENU = '/api/v1/restaurant_menu/menu',

  //Menu Media
  MENU_MEDIA = '/api/v1/restaurant_menu/menu_media',

  //Category
  CREATE_SECTION = '/api/v1/restaurant_menu/section',
  FETCH_SECTION = '/api/v1/restaurant_menu/section',
  FETCH_ALL_MENU_SECTIONS = '/api/v1/restaurant_menu/section',
  UPDATE_SECTION = '/api/v1/restaurant_menu/section',
  DELETE_SECTION = '/api/v1/restaurant_menu/section',

  //Cuisine
  CREATE_CUISINE = '/api/v1/restaurant_menu/cuisine',
  FETCH_CUISINE = '/api/v1/restaurant_menu/cuisine',
  FETCH_ALL_MENU_CUISINES = '/api/v1/restaurant_menu/cuisine',
  UPDATE_CUISINE = '/api/v1/restaurant_menu/cuisine',
  DELETE_CUISINE = '/api/v1/restaurant_menu/cuisine',

  // Orders
  GET_ALL_ORDERS = '/api/v1/restaurant_ordering/ordering',

  //Business
  CREATE_RESTAURANT = '/api/v1/business_acc/restaurant',
  GET_ALL_RESTAURANTS = '/api/v1/business_acc/restaurant',
  GET_RESTAURANT_BY_ID = '/api/v1/business_acc/restaurant',
  GET_ALL_ROOMS = '/api/v1/restaurant_table/room',
  GET_ALL_TABLES = 'api/v1/restaurant_table/room/table',
  CREATE_TABLE = 'api/v1/restaurant_table/room/table',
  UPDATE_TABLE = 'api/v1/restaurant_table/room/table',
  DELETE_TABLE = 'api/v1/restaurant_table/room/table',
  GET_TABLE_QR = 'api/v1/restaurant_table/table-qr',

  //Menu Item
  CREATE_MENU_ITEM = '/api/v1/restaurant_menu/menu/:menu_id/item/',
  UPDATE_MENU_ITEM = '/api/v1/restaurant_menu/menu/item',
  GET_ALL_MENU_ITEMS = '/api/v1/restaurant_menu/menu/item',
  GET_SECTION = '/api/v1/restaurant_menu/section/:section_id',
  DELETE_MENU_ITEM = '/api/v1/restaurant_menu/menu/item',

  //Menu Item Media
  MENU_ITEM_MEDIA = '/api/v1/restaurant_menu/menu/item_media',

  // Super admin
  SUPER_ADMIN_GET_USERS = '/api/v1/admin/users',
  SEND_REQUEST_RESPONSE = '/api/v1/admin/user',
  CREATE_WORKER = 'api/v1/business_acc/register_worker',
  UPDATE_WORKER = 'api/v1/business_acc/register_worker',
  DELETE_WORKER = 'api/v1/business_acc/register_worker'
}
