export enum ROUTER_NAMES {
  HOME = 'home',
  MENU = 'menu',
  MENU_TEMPLATE = 'menu-template',
  DISHES = 'menu-dishes',
  TABLES = 'manage-tebles',
  SINGLE_DISH_FORM = 'single-dish-form',
  SERVICING = 'servicing',
  ACCESS = 'access',
  CLIENTS = 'clients-access',
  REQUESTS = 'requests-access',
  SETTINGS = 'settings',
  LOGIN = 'login',
  SIGN_UP = 'sign-up',
  SIGN_UP_SENDED = 'sign-up-sended',
  RECOVERY = 'recovery',
  RECOVERY_CODE = 'recovery-code',
  RESET_PASSWORD = 'reset-password',
  RESET_PASSWORD_SUCCESS = 'reset-password-success'
}
