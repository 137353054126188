<template>
  <div class="add-category">
    <div class="add-category__title">
      <h3>{{ isEditing ? 'Edit category' : 'New category' }}</h3>
    </div>

    <div class="add-category__controls">
      <div class="add-category__controls--form">
        <Input
          placeholder="Heading"
          :value="categoryTitle"
          @update:value="(val: string) => (categoryTitle = val)"
        />
      </div>

      <div class="add-category__controls--buttons">
        <RedTextButton :text="isEditing ? 'Delete' : 'Cancel'" @click="cancelCategory" />

        <RedTonedButton :text="isEditing ? 'Edit' : 'Continue'" @click="confirmCategory" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useModalStore } from '@/stores/modalStore'
import { useMenuStore } from '@/stores/menuStore'
import { computed } from 'vue'
import Input from '@/components/shared/Input/Input.vue'
import RedTextButton from '@/components/shared/Buttons/RedTextButton.vue'
import RedTonedButton from '@/components/shared/Buttons/RedTonedButton.vue'
import { MODAL_MODE } from '@/utils/types/enums'
import { storeToRefs } from 'pinia'

const modalStore = useModalStore()
const menuStore = useMenuStore()

const { removeModal, activeModalContext: context } = modalStore
const { addCategory, editCategory, deleteCategory } = menuStore
const { currentCuisine } = storeToRefs(menuStore)

const categoryTitle = ref(context?.data?.name || '')

const isEditing = computed(() => {
  return context && context.mode === MODAL_MODE.EDIT
})

function confirmCategory() {
  if (isEditing.value) {
    editCategory(categoryTitle.value, context?.data?.id || -1)
  } else addCategory({ name: categoryTitle.value }, currentCuisine.value?.id || 0)

  removeModal()
}

function cancelCategory() {
  if (isEditing.value) {
    deleteCategory(context?.data?.id || -1)
  }

  removeModal()
}
</script>

<style scoped lang="scss">
@import './AddCategory.scss';
</style>
