<template>
  <ConfirmModal
    title="Are you sure you want to delete these dishes?"
    :description="
      context && Array.isArray(context?.data)
        ? getDishNamesDescription(context?.data as IMenuItem[])
        : ''
    "
    confirm-btn-text="Delete"
    reject-btn-text="Cancel"
    img="stress-mark.svg"
    @accept="removeDishesHandler"
    @reject="removeModal"
  />
</template>

<script setup lang="ts">
import ConfirmModal from '@/components/shared/ConfirmModal/ConfirmModal.vue'
import { useModalStore } from '@/stores/modalStore'
import { useDishStore } from '@/stores/dishStore'
import type { IMenuItem } from '@/utils/types/interfaces/services'

const { removeModal, activeModalContext: context } = useModalStore()
const { deleteDish } = useDishStore()

function getDishNamesDescription(dishes: IMenuItem[]) {
  if (!Array.isArray(dishes) || dishes.length === 0) return ''

  return `If you remove ${dishes
    .map((dish) => dish.name)
    .join(', ')}, this will also remove their associated data. This action can’t be undone.`
}

async function removeDishesHandler() {
  if (!context?.data) return

  try {
    if (Array.isArray(context.data)) {
      await Promise.all(
        context.data.map((dish) => {
          if (dish.id) deleteDish(dish.id)
        })
      )
    }
    removeModal()
  } catch (error) {
    console.error('Error deleting dishes:', error)
  }
}
</script>
