import { createI18n } from 'vue-i18n'
import { StorageService } from '@/services/storage.service'
import { STORAGE_KEYS } from '@/utils/types/enums'

type LocaleMessage = Record<string, any>
type LocaleMessages = Record<string, LocaleMessage>

async function loadLocaleMessages(): Promise<LocaleMessages> {
  // get all translation files
  const locales: LocaleMessage = import.meta.glob('../public/locales/*.json', { eager: true })
  const messages: LocaleMessages = {}
  for (const path in locales) {
    // get locale name from file name
    const matched = path.match(/\/([A-Za-z0-9-_]+)\.json$/i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      // assign content from json file to locale name key
      messages[locale] = locales[path].default
    }
  }

  return messages
}

function getCurrentLocale(): string {
  return StorageService.get(STORAGE_KEYS.TRANSLATION_LANGUAGE) || 'en'
}

export default createI18n({
  legacy: false, // you must specify 'legacy: false' for Vue 3
  locale: getCurrentLocale(), // set default locale
  fallbackLocale: 'en',
  messages: await loadLocaleMessages()
})
