export const requiredField = (value: string): string | boolean => {
  if (value) return true;

  return 'Field is required.';
};

export const maxLength25 = (value: string): string | boolean => {
  if (value?.length <= 25) return true;

  return 'Name must be less than 25 characters.';
};

export const validateEmail = (value: string): string | boolean => {
  if (/.+@.+\..+/.test(value)) return true;

  return 'E-mail must be valid.';
};
