import AddCategory from '@/components/modals/AddCategory/AddCategory.vue'
import AddMenu from '@/components/modals/AddMenu/AddMenu.vue'
import ChangeLanguage from '@/components/modals/ConfirmModals/ChangeLanguage.vue'
import DeleteIngredient from '@/components/modals/ConfirmModals/DeleteIngredient.vue'
import DeleteItem from '@/components/modals/ConfirmModals/DeleteItem.vue'
import UnsavedChanges from '@/components/modals/ConfirmModals/UnsavedChanges.vue'
import { MODALS_NAME } from '@/utils/types/enums/modal-names.enum'
import type { IModal } from '@/utils/types/interfaces/store/modal.interface'
import AddCuisine from '@/components/modals/AddCuisine/AddCuisine.vue'
import DeleteDish from '@/components/modals/ConfirmModals/DeleteDish.vue'
import AddClient from '@/components/modals/AddClient/AddClient.vue'
import AddTable from '@/components/modals/AddTable/AddTable.vue'
import AddMenuCuisine from '@/components/modals/AddMenuCuisine/AddMenuCuisine.vue'
import AddIngredient from '@/components/modals/AddIngredient/AddIngredient.vue'
import AddTag from '@/components/modals/AddTag/AddTag.vue'

export const MODALS_CONFIG: IModal[] = [
  {
    name: MODALS_NAME.ADD_MENU,
    modal: AddMenu
  },
  {
    name: MODALS_NAME.ADD_TABLE,
    modal: AddTable
  },
  {
    name: MODALS_NAME.ADD_CATEGORY,
    modal: AddCategory
  },
  {
    name: MODALS_NAME.ADD_CUISINE,
    modal: AddCuisine
  },
  {
    name: MODALS_NAME.ADD_MENU_CUISINE,
    modal: AddMenuCuisine
  },
  {
    name: MODALS_NAME.ADD_INGREDIENT,
    modal: AddIngredient
  },
  {
    name: MODALS_NAME.ADD_TAG,
    modal: AddTag
  },
  {
    name: MODALS_NAME.ADD_CLIENT,
    modal: AddClient
  },
  {
    name: MODALS_NAME.CONFIRM_ITEM_DELETE,
    modal: DeleteItem
  },
  {
    name: MODALS_NAME.CONFIRM_DISH_DELETE,
    modal: DeleteDish
  },
  {
    name: MODALS_NAME.CONFIRM_INGREDIENT_DELETE,
    modal: DeleteIngredient
  },
  {
    name: MODALS_NAME.CONFIRM_LANG_CHANGE,
    modal: ChangeLanguage
  },
  {
    name: MODALS_NAME.CONFIRM_UNSAVED,
    modal: UnsavedChanges
  }
]
