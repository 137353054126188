<template>
  <ConfirmModal 
    title="Unsaved changes"
    description="You have unsaved changes that will be lost if you proceed. Are you sure you want to leave this page without saving your work?"
    confirm-btn-text="Save" 
    reject-btn-text="Dont save" 
    img="diskette.svg" 
    @reject="rejectHandler"
    @accept="removeModal"
  />
</template>

<script setup lang="ts">
import ConfirmModal from '@/components/shared/ConfirmModal/ConfirmModal.vue';
import { useDishStore } from '@/stores/dishStore';
import { useModalStore } from '@/stores/modalStore';
import { ROUTER_URL } from '@/utils/types/enums';
import { useRouter } from 'vue-router';

const router = useRouter();

const { removeModal } = useModalStore();
const { resetStore } = useDishStore();

function rejectHandler() {
  router.push({ path: ROUTER_URL.DISHES });

  resetStore();
  removeModal();
};
</script>