import type { ThemeDefinition } from "vuetify";

export const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    'primary-red': '#D44949',
    'primary-white': '#FFFFFF',
    'input-gray': '#F6F6F6',
    'secondary-gray': '#14141499',
    'slate-grey': '#838383',
  },
  variables: {
    'input-radius': 8,
  },
}
