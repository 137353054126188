import { defineStore } from 'pinia'
import type { State, Getters, Actions } from '@/utils/types/interfaces/store/user.interface'
import { AuthService } from '@/services/auth.service'
import { StorageService } from '@/services/storage.service'
import { ROUTER_URL, STORAGE_KEYS, USER_TYPE } from '@/utils/types/enums'
import { jwtDecode } from 'jwt-decode'

export const useUserStore = defineStore<'user', State, Getters, Actions>('user', {
  state: () => ({
    user: {
      email: '',
      restaurants: [],
      user_type: null
    },
    access_token: StorageService.get(STORAGE_KEYS.ACCESS_TOKEN) || '',
    refresh_token: StorageService.get(STORAGE_KEYS.REFRESH_TOKEN) || ''
  }),
  getters: {
    isUserSuperAdmin: (state: State) => {
      return state.user.user_type === USER_TYPE.SUPER_ADMIN
    }
  },
  actions: {
    resetUserData() {
      StorageService.clear()

      this.$reset()
    },
    async refreshToken() {
      const res = (await AuthService.refreshToken())?.data
      if (!res) return false

      this.access_token = res.access_token
      this.refresh_token = res.refresh_token

      StorageService.set(STORAGE_KEYS.ACCESS_TOKEN_LIFE_TIME, jwtDecode(res.access_token).exp)
      StorageService.set(STORAGE_KEYS.ACCESS_TOKEN, res.access_token)
      StorageService.set(STORAGE_KEYS.REFRESH_TOKEN, res.refresh_token)

      return true
    },
    async login(body) {
      const res = (await AuthService.login(body))?.data
      if (!res) return

      this.access_token = res.access_token
      this.refresh_token = res.refresh_token

      StorageService.set(STORAGE_KEYS.ACCESS_TOKEN_LIFE_TIME, jwtDecode(res.access_token).exp)
      StorageService.set(STORAGE_KEYS.ACCESS_TOKEN, res.access_token)
      StorageService.set(STORAGE_KEYS.REFRESH_TOKEN, res.refresh_token)
      StorageService.set(STORAGE_KEYS.WAS_LOGGED, true)

      const user = (await AuthService.currentUser())?.data
      if (!user) return

      this.user = { ...user }

      this.$router.push({ path: ROUTER_URL.HOME })
    },
    async register(body) {
      const res = (await AuthService.register(body))?.data
      if (!res) return

      this.user = { ...res }

      this.$router.push({ path: ROUTER_URL.SIGN_UP_SENDED })
    },
    setUserData(data) {
      this.user = { ...data }
    }
  }
})
