<template>
  <div class="select-wrap">
    <span v-if="title" class="select-wrap__title">{{ title }}</span>

    <v-responsive class="mx-auto" max-width="100%">
      <v-select
        :placeholder="placeholder"
        :items="items"
        v-model="selectedValue"
        @update:model-value="emit('select:value', selectedValue)"
      />
    </v-responsive>
  </div>
</template>

<script setup lang="ts">
import type { ISelectProps } from '@/utils/types/interfaces/shared/props.interface'
import type { ISelectEmits } from '@/utils/types/interfaces/shared/emits.interface'
import { ref } from 'vue'

const props = defineProps<ISelectProps>()
const emit = defineEmits<ISelectEmits>()

const selectedValue = ref(
  props.defaultValue ? props.items.find((item) => item === props.defaultValue) : props.items[0]
)
</script>

<style lang="scss">
@import 'Select';
</style>
