import { defineStore } from 'pinia'
import type { State, Getters, Actions } from '@/utils/types/interfaces/store/modal.interface'
import { markRaw } from 'vue'
import { MODALS_CONFIG } from '@/utils/constants/modal-config.const'

export const useModalStore = defineStore<'modal', State, Getters, Actions>('modal', {
  state: () => ({
    modals: markRaw(MODALS_CONFIG),
    activeModals: [],
    activeModalContext: null
  }),
  actions: {
    addModal(name, context) {
      const target = this.modals?.find((m) => m.name === name)
      if (!target) return

      if (!context) {
        this.activeModalContext = null
      } else {
        this.activeModalContext = context
      }

      this.activeModals.push({ ...target })
    },
    removeModal() {
      if (!this.activeModals.length) return

      this.activeModals.pop()
      this.activeModalContext = null
    }
  }
})
