import SimpleList from '@/components/DrawerComponents/SimpleList/SimpleList.vue'
import { DRAWER_NAMES } from '@/utils/types/enums/drawer-names.enum'
import type { IDrawerItem } from '@/utils/types/interfaces/components'

export const DRAWER_COMPONENTS_CONFIG: IDrawerItem[] = [
  {
    name: DRAWER_NAMES.MENU,
    component: SimpleList,
    withAddButton: false
  },
  {
    name: DRAWER_NAMES.CATEGORY,
    component: SimpleList,
    withAddButton: true
  },
  {
    name: DRAWER_NAMES.CUISINE,
    component: SimpleList,
    withAddButton: true
  },
  {
    name: DRAWER_NAMES.CURRENCY,
    component: SimpleList,
    withAddButton: false
  },
  {
    name: DRAWER_NAMES.TRANSLATION,
    component: SimpleList,
    withAddButton: false
  },
  {
    name: DRAWER_NAMES.INGREDIENTS,
    component: SimpleList,
    withAddButton: true
  },
  {
    name: DRAWER_NAMES.TAGS,
    component: SimpleList,
    withAddButton: true
  }
]
