<template>
  <ConfirmModal 
    title="Are you sure to change language?"
    description="It will take some time to change. On average 2-3 minutes"
    confirm-btn-text="Change" 
    reject-btn-text="Cancel" 
    img="stress-mark-circle.svg" />
</template>

<script setup lang="ts">

</script>