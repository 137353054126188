import { isTokenExpired, wasLogged } from '@/utils/helpers';
import { type RouteLocationNormalized } from "vue-router";
import { ROUTER_NAMES } from "@/utils/types/enums";
import { useUserStore } from "@/stores/userStore";

export const authGuard = async (to: RouteLocationNormalized) => {
  const userStore = useUserStore();

  if (isTokenExpired() && to.meta.withAuthCheck) {
    const isResultSuccess = await userStore.refreshToken();

    if (!isResultSuccess) return { name: wasLogged() ? ROUTER_NAMES.LOGIN : ROUTER_NAMES.SIGN_UP };
  };
};
