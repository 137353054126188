import { MenuService } from '@/services/menu.service'
import { defineStore } from 'pinia'
import type { Actions, Getters, State } from '@/utils/types/interfaces/store/menu.interface'
import type { IImageUploadResponse } from '@/utils/types/interfaces/services'

export const useMenuStore = defineStore<'menu', State, Getters, Actions>('menu', {
  state: () => ({
    currentMenu: null,
    categories: [],
    menuList: [],
    currentCategory: null,
    activeMenuId: null,
    cuisines: [],
    currentCuisine: null,
    currency: ['USD', 'EUR', 'HUF', 'HKD']
  }),
  getters: {},
  actions: {
    setActiveMenuId(id) {
      this.activeMenuId = id
    },

    async duplicateMenu(id) {
      const menu = this.menuList.find((m) => m.id === id)
      if (!menu) return null

      const duplicatedMenu = await this.addMenu({
        name: menu.name,
        subtitles: menu.subtitles,
        image_url: menu.image_url
      })

      if (duplicatedMenu) {
        return {
          ...duplicatedMenu,
          image_url: menu.image_url
        }
      }

      return null
    },

    async addMenu({ name, subtitles = '', image_url = '' }) {
      const res = (
        await MenuService.createMenu({
          name,
          subtitles,
          image_url
        })
      )?.data
      if (!res) return null

      const id = res.id

      await this.fetchMenus()

      const target = this.menuList.find((m) => m.id === id)
      if (!target) return null

      return target
    },

    async editMenu({ name = '', subtitles = '', image_url = '' }, id) {
      const res = (
        await MenuService.updateMenu(
          {
            name,
            subtitles,
            image_url
          },
          id
        )
      )?.data

      if (!res) return

      this.fetchMenus()
    },

    async fetchMenus() {
      const res = (await MenuService.fetchAllMenus())?.data
      if (!res) {
        this.menuList = []
        return
      }

      this.menuList = [...res]
    },

    async fetchMenuById(menuId: number) {
      const res = (await MenuService.fetchMenuById(menuId))?.data

      if (!res) return

      this.menuList = this.menuList.map((menu) => (menu.id === menuId ? res : menu))
    },

    setCurrentMenu(id) {
      const current = this.menuList.find((m) => m.id === id)
      if (!current) return

      this.currentMenu = { ...current }
    },

    setMenuList(list) {
      this.menuList = [...list]
    },

    async deleteMenu(id) {
      const res = (await MenuService.deleteMenu(id))?.data

      if (!res) return

      this.fetchMenus()
    },

    async getMenuMedia(menuId) {
      const res = await MenuService.getMenuMedia(menuId)

      const isRequestSuccessful = res?.status === 200

      if (!isRequestSuccessful && !res?.data) return

      this.menuList = this.menuList.map((menu) =>
        menu.id === menuId ? { ...menu, image_src: res } : menu
      )
    },

    async uploadMenuMedia(menuId, imageFile): Promise<IImageUploadResponse | null> {
      const res = (await MenuService.uploadMenuMedia(menuId, imageFile))?.data

      if (!res) return null

      return res
    },

    async deleteMenuMedia(menuId) {
      const res = (await MenuService.deleteMenuMedia(menuId))?.data

      if (!res) return

      this.fetchMenuById(menuId)
    },

    async addCategory(category, cuisineId) {
      const res = (await MenuService.addCategory({ name: category.name }, cuisineId))?.data
      if (!res) return

      this.fetchAllCategories(this.currentCuisine?.id || 0)
    },

    async editCategory(name, id) {
      const res = (await MenuService.updateCategory({ name }, id))?.data
      if (!res) return

      this.fetchAllCategories(this.currentCuisine?.id || 0)
    },

    async fetchCategory(id) {
      const res = (await MenuService.fetchCategory(id))?.data
      if (!res) return

      this.currentCategory = res
    },

    async fetchAllCategories(cuisineId: number) {
      const res = (await MenuService.fetchAllCategories(cuisineId))?.data
      if (!res) {
        this.categories = []
        return
      }

      this.categories = [...res]
    },

    setCurrentCategory(category) {
      this.currentCategory = category
    },

    async deleteCategory(id) {
      const res = (await MenuService.deleteCategory(id))?.data
      if (!res) return

      this.fetchAllCategories(this.currentCuisine?.id || 0)
    },

    async addCuisine(cuisine, menuId) {
      const res = (await MenuService.addCuisine({ name: cuisine.name }, menuId))?.data
      if (!res) return

      this.fetchAllCuisines(this.currentMenu?.id || 0)
    },

    async editCuisine(name, id) {
      const res = (await MenuService.updateCuisine({ name }, id))?.data
      if (!res) return

      this.fetchAllCuisines(this.currentMenu?.id || 0)
    },

    async fetchCuisine(id) {
      const res = (await MenuService.fetchCuisine(id))?.data
      if (!res) return

      this.currentCuisine = res
    },

    async fetchAllCuisines(menuId) {
      const res = (await MenuService.fetchAllCuisines(menuId))?.data
      if (!res) {
        this.cuisines = []
        return
      }

      this.cuisines = [...res]
    },

    setCurrentCuisine(cuisine) {
      this.currentCuisine = cuisine
    },

    async deleteCuisine(id) {
      const res = (await MenuService.deleteCuisine(id))?.data
      if (!res) return

      this.fetchAllCuisines(this.currentMenu?.id || 0)
    },

    resetMenuStore() {
      this.$reset()
    }
  }
})
