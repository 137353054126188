import type {
  IDishRequest,
  IDishResponseItem
} from '@/utils/types/interfaces/components/dish.interface'
import { API, FormDataApi } from './api'
import { ENDPOINTS } from '@/utils/types/enums'
import { errorHandledTryCatch, insertQueryParam } from '@/utils/helpers'
import type { ICreateMenu, IImageUploadResponse } from '@/utils/types/interfaces/services'

class MenuItemService {
  static createMenuItem = async (body: IDishRequest, menuId: number, sectionId: number) =>
    errorHandledTryCatch<IDishRequest>(() =>
      API.post(ENDPOINTS.CREATE_MENU_ITEM.replace(':menu_id', `${menuId}`), body, {
        params: { section_id: sectionId }
      })
    )

  static deleteDish = async (id: number) =>
    errorHandledTryCatch<ICreateMenu>(() =>
      API.delete(insertQueryParam(id, ENDPOINTS.DELETE_MENU_ITEM))
    )

  static editMenuItem = async (body: IDishRequest, menuItemId: number) =>
    errorHandledTryCatch<IDishRequest>(() =>
      API.put(insertQueryParam(menuItemId, ENDPOINTS.UPDATE_MENU_ITEM), body)
    )

  static getAllMenuItems = async () =>
    errorHandledTryCatch<IDishResponseItem[]>(() => API.get(ENDPOINTS.GET_ALL_MENU_ITEMS))

  static getDishMedia = async (dishId: number) =>
    errorHandledTryCatch<string>(() => API.get(insertQueryParam(dishId, ENDPOINTS.MENU_ITEM_MEDIA)))

  static uploadDishMedia = async (dishId: number, imageFile: FormData) =>
    errorHandledTryCatch<IImageUploadResponse>(() =>
      FormDataApi.post(insertQueryParam(dishId, ENDPOINTS.MENU_ITEM_MEDIA), imageFile)
    )

  static deleteDishMedia = async (dishId: number) =>
    errorHandledTryCatch<string>(() =>
      API.delete(insertQueryParam(dishId, ENDPOINTS.MENU_ITEM_MEDIA))
    )

  static getSectionById = async (sectionId: number) =>
    errorHandledTryCatch<any>(() => API.get(insertQueryParam(sectionId, ENDPOINTS.FETCH_SECTION)))

  static getCuisineById = async (cuisineId: number) =>
    errorHandledTryCatch<any>(() => API.get(insertQueryParam(cuisineId, ENDPOINTS.FETCH_CUISINE)))
}

export { MenuItemService }
