<template>
  <RouterView v-if="isAuthView" v-slot="{ Component, route }">
    <component :is="Component" :key="route.path" />
  </RouterView>

  <LoginFormLayout v-if="isTokenExpired() && !isAuthView">
    <RouterView name="auth" v-slot="{ Component, route }">
      <Transition name="bounce">
        <component :is="Component" :key="route.path" />
      </Transition>
    </RouterView>
  </LoginFormLayout>

  <LoaderLayout v-if="isLoading">
    <Loader />
  </LoaderLayout>

  <ModalLayout v-for="item in activeModals" :key="item.name" :name="item.name">
    <component :is="toRaw(item.modal)" />
  </ModalLayout>

  <ErrorToast v-if="getIsErrorShow" :message="errorMessage" />
</template>

<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import { useModalStore } from '@/stores/modalStore'
import { storeToRefs } from 'pinia'
import { onMounted, toRaw } from 'vue'
import { useSystemStore } from '@/stores/systemStore'
import LoaderLayout from '@/layouts/LoaderLayout/LoaderLayout.vue'
import Loader from '@/components/Loader/Loader.vue'
import ErrorToast from '@/components/ErrorToast/ErrorToast.vue'
import { isTokenExpired } from '@/utils/helpers'
import { useUserStore } from '@/stores/userStore'
import { AuthService } from '@/services/auth.service'
import { useBusinessStore } from '@/stores/businessStore'
import { useMenuStore } from '@/stores/menuStore'
import ModalLayout from '@/layouts/ModalLayout/ModalLayout.vue'
import LoginFormLayout from '@/layouts/LoginFormLayout/LoginFormLayout.vue'
import { computed } from 'vue'
import { useDishStore } from './stores/dishStore'

const currentRoute = useRoute()

const menuStore = useMenuStore()
const dishStore = useDishStore()
const { setUserData, user } = useUserStore()
const { fetchRestaurants } = useBusinessStore()
const { activeModals } = storeToRefs(useModalStore())
const { isLoading, getIsErrorShow, errorMessage } = storeToRefs(useSystemStore())

const isAuthView = computed(() => !!currentRoute.meta.withAuthCheck)

onMounted(async () => {
  if (!user.email && !isTokenExpired()) {
    const user = (await AuthService.currentUser())?.data
    if (!user) return

    setUserData(user)

    const promises = [
      await fetchRestaurants(),
      await menuStore.fetchMenus(),
      await dishStore.fetchAllMenuItems()
    ]

    Promise.allSettled(promises)
  }
})
</script>

<style lang="scss">
@import 'assets/styles/reset.scss';
@import 'assets/styles/base.scss';
@import 'assets/styles/transitions.scss';
</style>
