<template>
  <div class="add-ingredient">
    <div class="add-ingredient__title">
      <h3>{{ isEditing ? 'Edit ingredient' : 'New ingredient' }}</h3>
    </div>

    <div class="add-ingredient__controls">
      <div class="add-ingredient__controls--form">
        <Input
          placeholder="Heading"
          :value="ingredientName"
          @update:value="(val: string) => (ingredientName = val)"
        />
      </div>

      <div class="add-ingredient__controls--buttons">
        <RedTextButton :text="isEditing ? 'Delete' : 'Cancel'" @click="removeIngredient" />

        <RedTonedButton :text="isEditing ? 'Edit' : 'Continue'" @click="handleAddIngredient" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useModalStore } from '@/stores/modalStore'
import { computed } from 'vue'
import Input from '@/components/shared/Input/Input.vue'
import RedTextButton from '@/components/shared/Buttons/RedTextButton.vue'
import RedTonedButton from '@/components/shared/Buttons/RedTonedButton.vue'
import { MODAL_MODE } from '@/utils/types/enums'
import { useDishStore } from '@/stores/dishStore'

const modalStore = useModalStore()
const dishStore = useDishStore()

const { removeModal, activeModalContext: context } = modalStore
const { addIngredient } = dishStore

const ingredientName = ref(context?.data?.name || '')

const isEditing = computed(() => {
  return context && context.mode === MODAL_MODE.EDIT
})

function handleAddIngredient() {
  if (isEditing.value) {
    // editCuisine(cuisineTitle.value, context?.data?.id || -1)
  } else addIngredient({ name: ingredientName.value, imageUrl: '' })

  removeModal()
}

function removeIngredient() {
  if (isEditing.value) {
    // deleteCuisine(context?.data?.id || -1)
  }

  removeModal()
}
</script>

<style scoped lang="scss">
@import 'AddIngredient';
</style>
