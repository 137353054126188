<template>
  <v-btn ripple variant="tonal" class="text-none bg-slate-grey text-primary-white" rounded="lg" density="default"
    :text="text" :disabled="disabled" @click="emit('click')" />
</template>

<script setup lang="ts">
import {
  type GreyTonedButtonProps,
  type IButtonEmits
} from '@/utils/types/interfaces/shared';


defineProps<GreyTonedButtonProps>();
const emit = defineEmits<IButtonEmits>();
</script>
