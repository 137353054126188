<template>
  <ConfirmModal 
    title="Are you sure to delete menu?"
    :description="`If you will remove  ${context?.data?.name || ''}, this will also remove its associated data. This can’t be undone.`"
    confirm-btn-text="Delete"
    reject-btn-text="Cancel"
    img="stress-mark.svg"
    @accept="removeMenuHandler"
    @reject="removeModal"
  />
</template>

<script setup lang="ts">
import ConfirmModal from '@/components/shared/ConfirmModal/ConfirmModal.vue';
import { useMenuStore } from '@/stores/menuStore';
import { useModalStore } from '@/stores/modalStore';

const { removeModal, activeModalContext: context } = useModalStore();
const { deleteMenu } = useMenuStore();

async function removeMenuHandler() {
  if (!context?.data?.id) return;

  await deleteMenu(context.data.id);

  removeModal();
};
</script>
