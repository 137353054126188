<template>
  <v-btn
    ripple
    size="large"
    :variant="border ? 'outlined' : 'text'"
    class="text-none bg-primary-white text-primary-red border-primary-red border-opacity-900"
    :border="border"
    :width="width || 'unset'"
    :height="height || '36px'"
    :text="text"
    :rounded="rounded || 'xl'"
    :disabled="disabled"
    :density="density || 'default'"
    @click="emit('click')"
  />
</template>

<script setup lang="ts">
import { type RedTextButtonProps, type IButtonEmits } from '@/utils/types/interfaces/shared'

defineProps<RedTextButtonProps>()
const emit = defineEmits<IButtonEmits>()
</script>
