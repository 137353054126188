<template>
  <div class="error-toast" @click="systemStore.clearErrorMessage()">
    <img alt="stress mark" :src="getImagePath('error-toast-icon.svg')" />

    <p>{{ message }}</p>
  </div>
</template>

<script setup lang="ts">
import type { ErrorToastProps } from '@/utils/types/interfaces/components';
import { useSystemStore } from '@/stores/systemStore';
import { getImagePath } from '@/utils/helpers';

const systemStore = useSystemStore();

defineProps<ErrorToastProps>();
</script>

<style scoped>
@import './ErrorToast.scss';
</style>