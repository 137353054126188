<template>
  <div class="add-tag">
    <div class="add-tag__title">
      <h3>{{ isEditing ? 'Edit tag' : 'New tag' }}</h3>
    </div>

    <div class="add-tag__controls">
      <div class="add-tag__controls--form">
        <Input
          placeholder="Heading"
          :value="tagName"
          @update:value="(val: string) => (tagName = val)"
        />
      </div>

      <div class="add-tag__controls--buttons">
        <RedTextButton :text="isEditing ? 'Delete' : 'Cancel'" @click="removeTag" />

        <RedTonedButton :text="isEditing ? 'Edit' : 'Continue'" @click="handleAddTag" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useModalStore } from '@/stores/modalStore'
import { computed } from 'vue'
import Input from '@/components/shared/Input/Input.vue'
import RedTextButton from '@/components/shared/Buttons/RedTextButton.vue'
import RedTonedButton from '@/components/shared/Buttons/RedTonedButton.vue'
import { MODAL_MODE } from '@/utils/types/enums'
import { useDishStore } from '@/stores/dishStore'

const modalStore = useModalStore()
const dishStore = useDishStore()

const { removeModal, activeModalContext: context } = modalStore
const { addTag } = dishStore

const tagName = ref(context?.data?.name || '')

const isEditing = computed(() => {
  return context && context.mode === MODAL_MODE.EDIT
})

function handleAddTag() {
  if (isEditing.value) {
    // editCuisine(cuisineTitle.value, context?.data?.id || -1)
  } else addTag(tagName.value)

  removeModal()
}

function removeTag() {
  if (isEditing.value) {
    // deleteCuisine(context?.data?.id || -1)
  }

  removeModal()
}
</script>

<style scoped lang="scss">
@import 'AddTag';
</style>
