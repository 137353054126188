import { errorHandledTryCatch } from '@/utils/helpers'
import { API } from '@/services/api'
import { ENDPOINTS } from '@/utils/types/enums'
import type { ICreateTable } from '@/utils/types/interfaces/services'
import type {
  ICreateWorkerInput,
  ISendRequestResponseInput,
  IUpdateWorkerInput
} from '@/utils/types/interfaces/store/access.interface'

class SuperAdminService {
  static getAllUsers = async () =>
    errorHandledTryCatch(() => API.get(ENDPOINTS.SUPER_ADMIN_GET_USERS + '/?skip=0&limit=100'))

  static sendRequestResponse = async ({ userId, response }: ISendRequestResponseInput) =>
    errorHandledTryCatch(() =>
      API.put(ENDPOINTS.SEND_REQUEST_RESPONSE + `/${userId}/`, { isActive: response })
    )

  static createWorker = async (body: ICreateWorkerInput) => {
    return errorHandledTryCatch(() => API.post(ENDPOINTS.CREATE_WORKER, body))
  }

  static updateWorker = async ({ id, ...body }: IUpdateWorkerInput) => {
    return errorHandledTryCatch<ICreateTable>(() =>
      API.put(ENDPOINTS.UPDATE_WORKER + `/${id}/`, body)
    )
  }

  static deleteWorker = async (workerId: number) => {
    return errorHandledTryCatch<ICreateTable>(() =>
      API.delete(ENDPOINTS.DELETE_WORKER + `/${workerId}/`)
    )
  }
}

export { SuperAdminService }
