import type { ILoginResponse, IRegisterRequest, IRegisterResponse } from "@/utils/types/interfaces/services/auth";
import { API } from "./api";
import { ENDPOINTS } from "@/utils/types/enums";
import { errorHandledTryCatch } from "@/utils/helpers";

class AuthService {
  static login = async (body: IRegisterRequest) => 
    errorHandledTryCatch<ILoginResponse>(() => API.post(ENDPOINTS.LOGIN, body));

  static register = async (body: IRegisterRequest) => 
    errorHandledTryCatch<IRegisterResponse>(() => API.post(ENDPOINTS.REGISTER, body));
  
  static logout = async () => 
    errorHandledTryCatch<string>(() => API.get(ENDPOINTS.LOGOUT));

  static refreshToken = async () => 
    errorHandledTryCatch<ILoginResponse>(() => API.post(ENDPOINTS.REFRESH_TOKEN));

  static currentUser = async () => 
    errorHandledTryCatch<IRegisterResponse>(() => API.post(ENDPOINTS.CURRENT_USER));
};

export { AuthService };
