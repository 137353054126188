import { DRAWER_COMPONENTS_CONFIG } from '@/utils/constants/drawer-config.const'
import { defineStore } from 'pinia'
import type { State, Getters, Actions } from '@/utils/types/interfaces/store/drawer.interface'
import { markRaw } from 'vue'

export const useDrawerStore = defineStore<'drawer', State, Getters, Actions>('drawer', {
  state: () => ({
    drawerComponents: markRaw(DRAWER_COMPONENTS_CONFIG),
    activeDrawer: null,
    context: {
      returnedData: []
    }
  }),
  actions: {
    setDrawer(name) {
      const target = this.drawerComponents?.find((c) => c.name === name)
      if (!target) return

      this.activeDrawer = target
    },
    setReturnedData(data) {
      this.context.returnedData = data
    },
    removeDrawer() {
      this.activeDrawer = null
      this.context.returnedData = []
    }
  }
})
