<template>
  <div class="add-cuisine">
    <div class="add-cuisine__title">
      <h3>{{ isEditing ? 'Edit cuisine' : 'New cuisine' }}</h3>
    </div>

    <div class="add-cuisine__controls">
      <div class="add-cuisine__controls--form">
        <Input
          placeholder="Heading"
          :value="cuisineTitle"
          @update:value="(val: string) => (cuisineTitle = val)"
        />
      </div>

      <div class="add-cuisine__controls--buttons">
        <RedTextButton :text="isEditing ? 'Delete' : 'Cancel'" @click="cancelCuisine" />

        <RedTonedButton :text="isEditing ? 'Edit' : 'Continue'" @click="confirmCuisine" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useModalStore } from '@/stores/modalStore'
import { useMenuStore } from '@/stores/menuStore'
import { computed } from 'vue'
import Input from '@/components/shared/Input/Input.vue'
import RedTextButton from '@/components/shared/Buttons/RedTextButton.vue'
import RedTonedButton from '@/components/shared/Buttons/RedTonedButton.vue'
import { MODAL_MODE } from '@/utils/types/enums'
import { storeToRefs } from 'pinia'

const modalStore = useModalStore()
const menuStore = useMenuStore()

const { removeModal, activeModalContext: context } = modalStore
const { addCuisine, editCuisine, deleteCuisine } = menuStore
const { currentMenu } = storeToRefs(menuStore)

const cuisineTitle = ref(context?.data?.name || '')

const isEditing = computed(() => {
  return context && context.mode === MODAL_MODE.EDIT
})

function confirmCuisine() {
  if (isEditing.value) {
    editCuisine(cuisineTitle.value, context?.data?.id || -1)
  } else addCuisine({ name: cuisineTitle.value }, currentMenu.value?.id || 0)

  removeModal()
}

function cancelCuisine() {
  if (isEditing.value) {
    deleteCuisine(context?.data?.id || -1)
  }

  removeModal()
}
</script>

<style scoped lang="scss">
@import './AddCuisine';
</style>
