import { lightTheme } from '@/utils/constants'
import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import i18n from '@/i18n'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme
    }
  }
})

const pinia = createPinia()

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

const app = createApp(App)

app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(i18n)

app.mount('#app')
