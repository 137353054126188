export enum LANGUAGES_NAMES {
  'de' = 'German',
  'fr' = 'French',
  'es' = 'Spanish',
  'en' = 'English',
  'hi' = 'Hindi',
  'it' = 'Italian',
  'ja' = 'Japanese',
  'tr' = 'Turkish',
  'uk' = 'Ukrainian',
  'zh-Hans-CN' = 'Chinese (simplified)'
}

export enum REVERSE_LANGUAGE_NAMES {
  'German' = 'de',
  'French' = 'fr',
  'Spanish' = 'es',
  'English' = 'en',
  'Hindi' = 'hi',
  'Italian' = 'it',
  'Japanese' = 'ja',
  'Turkish' = 'tr',
  'Ukrainian' = 'uk',
  'zh-Hans-Chinese (simplified)' = 'zh-Hans-CN'
}
